import { axios } from '@webstore-monorepo/shared/contexts/axios-provider';
import type { AxiosRequestConfig } from 'axios';

axios.interceptors.response.use((res) => res);

axios.interceptors.request.use((config) => {
  return {
    ...config,
    url: new RegExp('^http').test(config.url ?? '') ? config.url : `${window.WEBSTORE_GATEWAY_URL}${config.url}`,
  };
});
axios.interceptors.request.use(setCommonQueryParameters);

/**
 * only for v0 api
 */
function setCommonQueryParameters(config: AxiosRequestConfig) {
  if (!config.url?.includes('api') || config.url?.includes('/api/v2') || config.url?.includes('api/v1/merchants/')) {
    return config;
  }

  if (!config.headers) {
    config.headers = {};
  }

  const { merchantId, storeId } = window;

  if (merchantId && storeId) {
    config.params = config.params || {};
    config.params['merchantId'] = merchantId;
    config.params['storeId'] = storeId;
  }

  return config;
}

export default axios;
